import { memo } from 'react';
import { useMediaQuery } from 'react-responsive';

import useTranslation from 'next-translate/useTranslation';
import { useDispatch } from 'react-redux';

import LanguageSelector from '../LanguageSelector';
import NavbarLogoWrapper from '../Logo/NavbarLogoWrapper';
import NavigationDrawer from '../NavigationDrawer/NavigationDrawer';
import SearchDrawer from '../SearchDrawer/SearchDrawer';
import SettingsDrawer from '../SettingsDrawer/SettingsDrawer';

import styles from './NavbarBody.module.scss';
import ProfileAvatarButton from './ProfileAvatarButton';

import Button, { ButtonShape, ButtonSize, ButtonVariant } from '@/dls/Button/Button';
import IconMenu from '@/icons/menu.svg';
import IconSearch from '@/icons/search.svg';
import IconSettings from '@/icons/settings.svg';
import {
  setIsSearchDrawerOpen,
  setIsNavigationDrawerOpen,
  setIsSettingsDrawerOpen,
} from '@/redux/slices/navbar';
import { logEvent } from '@/utils/eventLogger';
import IconInfo from '@/icons/info.svg';
import IconMosque from '@/icons/mosque.svg';
import IconReciting from '@/icons/reciting-2.svg';
import IconOpenBook from '@/icons/book-open.svg';
import IconDonate from '@/icons/donate.svg';
import IconQuran from '@/icons/quran.svg';
import IconTrust from '@/icons/trust.svg';
/**
 * Log drawer events.
 *
 * @param {string} drawerName
 */
const logDrawerOpenEvent = (drawerName: string) => {
  // eslint-disable-next-line i18next/no-literal-string
  logEvent(`drawer_${drawerName}_open`);
};

const NavbarBody: React.FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const openNavigationDrawer = () => {
    logDrawerOpenEvent('navigation');
    dispatch({ type: setIsNavigationDrawerOpen.type, payload: true });
  };

  const openSearchDrawer = () => {
    logDrawerOpenEvent('search');
    dispatch({ type: setIsSearchDrawerOpen.type, payload: true });
  };

  const openSettingsDrawer = () => {
    logDrawerOpenEvent('settings');
    dispatch({ type: setIsSettingsDrawerOpen.type, payload: true });
  };

  const isMobile = useMediaQuery({maxDeviceWidth:767});

  const isTab = useMediaQuery({maxDeviceWidth:1024});

  return (
    <div className={styles.itemsContainer}>
      <div className={styles.centerVertically}>
        <div className={styles.leftCTA}>
          {isMobile && (
            <>
              <Button
                tooltip={t('menu')}
                variant={ButtonVariant.Ghost}
                shape={ButtonShape.Circle}
                onClick={openNavigationDrawer}
                ariaLabel={t('aria.nav-drawer-open')}
              >
                <IconMenu />
              </Button>
              <NavigationDrawer />
            </>
          )}
          <NavbarLogoWrapper />
          {!isMobile && (<>
            <Button
              tooltip={t('masjid')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Compact}
              size={ButtonSize.Large}
              ariaLabel={t('masjid')}
              className={styles.leftCTA}
              href='https://foundation.alfaqr.com/masjid-2/'
              shouldPrefetch={false}
              isNewTab={true}
              suffix={!isMobile && !isTab ? <p>{t('masjid')}</p> : ''}
            >
              <IconMosque />
            </Button>
            <Button
              tooltip={t('books')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Compact}
              size={ButtonSize.Large}
              ariaLabel={t('books')}
              className={styles.leftCTA}
              href='https://foundation.alfaqr.com/books/'
              shouldPrefetch={false}
              isNewTab={true}
              suffix={!isMobile && !isTab ? t('books') : ''}
            >
              <IconOpenBook />
            </Button>
            <Button
              tooltip={t('reciters')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Compact}
              size={ButtonSize.Large}
              ariaLabel={t('reciters')}
              className={styles.leftCTA}
              href='/reciters'
              shouldPrefetch={false}
              isNewTab={false}
              suffix={!isMobile && !isTab ? <p>{t('reciters')}</p> : ''}
            >
              <IconReciting />
            </Button>
            <Button
              tooltip={t('trust')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Compact}
              size={ButtonSize.Large}
              ariaLabel={t('trust')}
              className={styles.leftCTA}
              href='https://foundation.alfaqr.com/trust-2/'
              shouldPrefetch={false}
              isNewTab={true}
              suffix={!isMobile && !isTab ? <p>{t('trust')}</p> : ''}
            >
              <IconTrust />
            </Button>
            <Button
              tooltip={t('donate')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Compact}
              size={ButtonSize.Large}
              ariaLabel={t('donate')}
              className={styles.leftCTA}
              href='https://foundation.alfaqr.com/donation/'
              shouldPrefetch={false}
              isNewTab={true}
              suffix={!isMobile && !isTab ? t('donate') : ''}
            >
              <IconDonate />
            </Button>
            <Button
              tooltip={t('quranicterms')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Compact}
              size={ButtonSize.Large}
              ariaLabel={t('quranicterms')}
              className={styles.leftCTA}
              href='https://foundation.alfaqr.com/quranic-terms/'
              shouldPrefetch={false}
              isNewTab={true}
              suffix={!isMobile && !isTab ? <p>{t('quranicterms')}</p> : ''}
            >
              <IconQuran />
            </Button>
            <Button
              tooltip={t('foundation')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Compact}
              size={ButtonSize.Large}
              ariaLabel={t('foundation')}
              className={styles.leftCTA}
              href='https://foundation.alfaqr.com/foundation/'
              shouldPrefetch={false}
              isNewTab={true}
              suffix={!isMobile && !isTab ? <p>{t('foundation')}</p> : ''}
            >
              <IconInfo />
            </Button>
          </>
          )}
        </div>
      </div>
      <div className={styles.centerVertically}>
        <div className={styles.rightCTA}>
          <>
            <ProfileAvatarButton />
            <LanguageSelector />
            <Button
              tooltip={t('settings.title')}
              shape={ButtonShape.Circle}
              variant={ButtonVariant.Ghost}
              onClick={openSettingsDrawer}
              ariaLabel={t('aria.change-settings')}
              id="settings-button"
            >
              <IconSettings />
            </Button>
            <SettingsDrawer />
          </>
          <>
            <Button
              tooltip={t('search.title')}
              variant={ButtonVariant.Ghost}
              onClick={openSearchDrawer}
              shape={ButtonShape.Circle}
              shouldFlipOnRTL={false}
              ariaLabel={t('search.title')}
            >
              <IconSearch />
            </Button>
            <SearchDrawer />
          </>
        </div>
      </div>
    </div>
  );
};

export default memo(NavbarBody);
