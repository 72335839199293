const BASE_OG_URL = 'https://alfaqr.com';

interface BaseOgOptions {
  locale: string;
}

export const getDefaultOgImageUrl = ({ locale }: BaseOgOptions) =>
  `${BASE_OG_URL}/images/og.png?lang=${locale}`;

export const getAboutTheQuranImageUrl = ({ locale }: BaseOgOptions) =>
  `${BASE_OG_URL}/images/about-the-quran.png?lang=${locale}`;

export const getLearningPlansImageUrl = ({ locale }: BaseOgOptions) =>
  `${BASE_OG_URL}/images/learning-plans.png?lang=${locale}`;

export const getMediaGeneratorOgImageUrl = ({ locale }: BaseOgOptions) =>
  `${BASE_OG_URL}/images/media.png?lang=${locale}`;

export const getChapterOgImageUrl = ({
  chapterId,
  locale,
  verseNumber,
}: BaseOgOptions & {
  chapterId: string | number;
  verseNumber?: string | number;
}) => {
  // const url = `${BASE_OG_URL}/api/og/chapter/${chapterId}?lang=${locale}`;
  const url = `${BASE_OG_URL}/images/og.png`;

  // if (!verseNumber) 
    return url;
  // return `${url}&verse=${verseNumber}`;
};
